(function () {
  'use strict';

  /* @ngdoc object
   * @name events.vehicles.deposits
   * @description
   *
   */
  angular
    .module('events.vehicles.deposits', [
      'ui.router',
      'events.vehicles.deposits.list',
      'events.vehicles.deposits.edit'
    ]);
}());
